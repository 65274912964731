import React from "react"
import { Link, graphql } from "gatsby"
import { StyledLink} from "../components/nav"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogList = ({data, location}) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const posts = data.allMarkdownRemark.nodes
    const author = data.site.siteMetadata?.author?.name

    return (
      <Layout location={location} title={siteTitle} author={author}>
        <SEO title={`Cheatsheets page`} />
        <div className="cheatsheet-list-layout">
          <h2>
            <StyledLink to="/projects">other projects</StyledLink>
          </h2>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug
            return (
              <article
                key={post.fields.slug}
                className="post-list-item cheatsheet-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <h2 className="cheatsheet-name">
                  <Link to={post.fields.slug} itemProp="url">
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h2>
                <p
                  className="cheatsheet-description"
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description,
                  }}
                  itemProp="description"
                />
              </article>
            )
          })}
        </div>
      </Layout>
    )
}

export default BlogList

export const cheatsheetList = graphql`
  query CheatsheetListQuery {
    site {
      siteMetadata {
        author {
          name
        }
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "cheatsheets" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
        }
      }
    }
  }
`
